<script>
import { initFlowbite } from 'flowbite'

export default defineComponent({
  setup() {
    const layout = ref('')
    const route = useRoute()
    const metaPage = computed(() => route.meta)

    const setLayout = () => {
      if (metaPage.value.layout !== undefined)
        layout.value = metaPage.value.layout
      else
        layout.value = 'template'
    }

    onMounted(() => {
      initFlowbite()
      setLayout()
      const allInputs = document.querySelectorAll('input')
      allInputs.forEach((input) => {
        input.setAttribute('autocomplete', 'off')
      })
    })

    watchEffect(() => {
      setLayout()
    })

    return {
      layout,
    }
  },
})
</script>

<template>
  <div>
    <Head>
      <Title>Sally CMS</Title>
    </Head>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
